import React, { Component } from 'react';
import Link from '@mui/material/Link';
import { withTranslation } from 'react-i18next';
import fr from '../images/fr.png';
import en from '../images/en.png';
import ahc from '../images/AHC_full_white.png';
import './styles/Header.scss';
class Header extends Component {
    render() {
        const { t, i18n } = this.props;
        return (
            <>
                <nav>
                    <ul>
                        <li><Link href="/properties">{t('Apartments')}</Link></li>
                        <li className="logo_ahc"><Link href="/"><img src={ahc} alt="logo"/></Link></li>
                        <li><Link href="/contact">{t('Contact')}</Link></li>
                        <li className="flag" onClick={() => {i18n.changeLanguage('fr')}}>
                            <img src={fr} alt="fr"/>
                        </li>
                        <li className="flag" onClick={() => {i18n.changeLanguage('en')}}>
                            <img src={en} alt="en"/>
                        </li>
                    </ul>
                </nav>
            </>
        )
    }
}
export default withTranslation()(Header);