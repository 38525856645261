import axios from './BedsApiConfig';

class BedsApi {


    static call(method, url, data = {}, option = null) {

        const TOKEN = process.env.REACT_APP_BEDS_API_TOKEN_ARNAUD;

        // automatic add token
        if (data.authentication === undefined) {
            data.authentication = {
                apiKey: TOKEN
            }
        }



        // optional
        if (option != null) {
            if (option.propKey !== undefined) {
                data.authentication.propKey = option.propKey;
            }
            if (option.apiKey !== undefined && option.apiKey !== null) {
                data.authentication.apiKey = option.apiKey;
            }
        }


        return axios.request({
            method: method,
            url: url,
            data: JSON.stringify(data)
        }).then (res => {
            return res;
        }).catch(err => {
            return err.response;
        })
    }
}
export default BedsApi;