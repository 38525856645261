import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import React from "react";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {

            fr: {
                translations: {
                    "Search": "Rechercher",
                    "Check-in": "Arrivée",
                    "Check-out": "Départ",

                    "lang": "fr",
                    "This website uses cookies to enhance the user experience.": "Ce site web utilise des cookies pour améliorer l'expérience utilisateur.",
                    "Decline": "Refuser",
                    "Accept": "Accepter",
                    "Book": "Reserver",
                    "My Book": "Ma réservation",
                    "See our offers": "Voir nos offres",
                    "No apartment matches your dates": "Aucun appartement ne correspond à vos dates",
                    "Equipped and Furnished Short Term Apartments": "Appartements Equipés et Meublés de Courte Durée",
                    "Equipped and furnished short term apartments specially designed for professionals, tourists and athletes. Don't wait any longer, come and discover the apartment that suits you for your stay.":"Des appartements équipés et meublés de courte durée spécialement conçus pour professionnels, touristes et sportifs.\n" +
                    "Sans plus attendre, venez découvrir l'appartement qui vous convient pour votre séjour.",

                    "Home": "Accueil",
                    "Apartments": "Appartements",
                    "About": "A propos",

                    "Our Properties": "Nos locations",
                    "From": "À partir de",
                    "/night*": "/nuit*",
                    "Learn more": "En savoir plus",
                    "* Without cleaning fee and tourist tax": "* Hors frais de ménage et taxes de séjour",

                    "Legal Notice": "Mentions Légales",
                    "Terms of Sales": "CGV",

                    "Free NETFLIX platform on our TVs": "Plateforme NETFLIX gratuit sur nos TV",

                    "Check-in performed physically on site": "Check-in effectué physiquement sur place",
                    "Professional concierge": "Conciergerie professionnelle",
                    "Concierge": "Conciergerie",

                    "Secure platform for reservation payment": "Plateforme sécurisée pour paiement de réservation",
                    "Credit Card": "Paiement CB",

                    "Parking": "Parking",
                    "Free private and secure parking space (exterior or interior) at each apartment": "Place de stationnement privative et sécurisée gratuite (extérieur ou intérieur) à chaque appartement",

                    "Reduced price": "Tarifs réduits",
                    "Reduced prices directly on our internal booking platform": "Tarifs réduits directement sur notre plateforme de réservation interne",

                    "Security deposit": "Dépôt de garantie",
                    "Security deposit by bank imprint or provisional bank collection according to the duration of the stay": "Dépôt de garantie par empreinte bancaire ou encaissement bancaire provisoire selon durée du séjour",

                    "Also on": "Également sur",
                    "Also visible on Airbnb/Booking/Abritel platforms": "Visible également sur les plateformes Airbnb/Booking/Abritel",

                    "Rental contract": "Contrat location",
                    "Establishment of a seasonal rental contract online": "Etablissement d'un contrat de location saisonnière en ligne",

                    "Invoice": "Facture",
                    "Invoice stay sent automatically the day before check-out": "Facture séjour envoyée automatiquement la veille de votre sortie des lieux",

                    "Appart'HomeCity apartments":"Appartements Appart'HomeCity",
                    "Nationalities welcomed":"Nationalitées accueillies",
                    "Travelers received":"Voyageurs reçus",
                    "Average rating for travelers":"Note moyenne voyageurs",
                }
            },
            en: {
                translations: {
                    "lang": "en"
                }
            }
        },
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
