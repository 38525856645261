import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './Contact.scss'
import ContactInfo from "./ContactInfo";

class Contact extends Component {

    componentDidMount() {
        let h = document.getElementsByClassName("cardContact")[0].offsetTop
        window.scrollTo({top: h, behavior: 'smooth'});
    }

    render() {
        return (
            <div className="contact">
                <div className="top"></div>
                <div className="container-fluid">
                    <Helmet>
                        <title>Contact</title>
                    </Helmet>
                    <Card>
                        <CardContent>
                            <Grid container direction="row" justifyContent="center">
                                <Grid item xs={6} md={4} lg={3}>
                                    <ContactInfo
                                        nom="Arnaud LE HÉNAFF"
                                        tel="+33 7 49 17 53 53"
                                        mail="contact@apparthomecity.fr"
                                        adress="111, rue de Lessard - 76100 ROUEN <br/>EI LE HENAFF ARNAUD<br/>SIREN : 821 486 206"
                                    />
                                </Grid>
                                {/*<Grid item xs={6} md={4} lg={3}>*/}
                                    {/*<ContactInfo*/}
                                        {/*nom="Nathalie VASSEUR"*/}
                                        {/*tel="+33 6 32 85 01 53"*/}
                                        {/*mail="contact@apparthomecity.fr"*/}
                                        {/*adress="17c, avenue Jacques Chastellain - 76100 ROUEN <br/>EI VASSEUR NATHALIE<br/>SIREN : 345 078 075"*/}
                                    {/*/>*/}
                                {/*</Grid>*/}
                            </Grid>

                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}
export default Contact;