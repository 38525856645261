import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './MentionLegales.scss'
import Link from '@mui/material/Link';

class MentionLegales extends Component {

    name = "APPARTHOMECITY.FR"
    componentDidMount() {
    }

    render() {
        return (
            <div className="mentionlegales">
                <div className="top"></div>
                <div className="container">
                    <Helmet>
                        <title>Mentions légales</title>
                    </Helmet>
                    <Card>
                        <CardContent>
                            <h1> MENTIONS LEGALES </h1>

                            <p> Cette page décrit les conditions d'utilisation qui s'appliquent à tout utilisateur du site apparthomecity.fr. En les consultant, vous reconnaissez en avoir pris connaissance et vous vous engagez à les respecter sans réserve. EI LE HENAFF ARNAUD se réserve le droit de modifier les présentes mentions légales à tout moment et sans préavis ; vous vous engagez donc à les consulter régulièrement.</p>

                            <h2> 1. PRÉSENTATION DU SITE </h2>

                            Le site apparthomecity.com (ci-après "le Site") est la propriété exclusive de EI LE HENAFF ARNAUD (ci-après "l'Editeur"), Entreprise Individuelle, immatriculée sous le numéro 821 486 206 et dont le siège social est 111, rue de lessard - 76100 ROUEN.<br/>
                            Le Directeur de Publication du Site est Monsieur Arnaud Le Hénaff, en qualité de Directeur de EI LE HENAFF ARNAUD.<br/>
                            Les sociétés qui ont participé à la réalisation du Site sont :<br/>
                            Réalisation technique  : <Link color="inherit" underline="always" href="https://www.linkedin.com/in/ludovic-le-henaff-b4767087/">Le Henaff Ludovic</Link>
                             <br/>
                            Hebergement : OVH SAS - 2 rue Kellermann - 59100 Roubaix - France <br/>
                            Le Site peut donner accès à d'autres sites appartenant ou non à l'Editeur, susceptibles de disposer de leurs propres notices légales, qu'il convient de consulter et de respecter.<br/>
                            L'utilisateur du Site reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser le Site.<br/>

                            <h2> 2. CONDITIONS DE SERVICE </h2>
                            L'utilisateur reconnaît avoir été informé que le Site est accessible 24h/24h et 7j/7, sauf en cas de force majeure, difficultés informatiques, difficultés liées aux réseaux de télécommunications ou difficultés techniques. Pour des raisons de maintenance, l'Editeur pourra interrompre l'accès au site et s'efforcera d'en avertir préalablement les utilisateurs.<br/>
                            L'Editeur et ses prestataires déclinent toute responsabilité pour les difficultés rencontrées lors de l'accès au Site et ne sauraient être tenus pour responsables des dommages directs ou indirects qui pourraient résulter de l'accès ou de l'utilisation du Site, y compris l'inaccessibilité, les pertes de données, détériorations, destructions ou virus qui pourraient affecter l'équipement informatique de l'utilisateur, et/ou de la présence de virus sur son site.<br/>
                            L'utilisateur déclare que le coût de l'accès et de la navigation sur le Site est à sa charge exclusive. Dans l'hypothèse où l'utilisateur accèderait au Site à partir d'un autre Etat que la France, il s'engage à s'assurer du respect de la législation localement applicable.<br/>

                            <h2> 3. INFORMATIONS CONTENUES DANS LE SITE </h2>
                            Les informations fournies sur le Site le sont à titre informatif. L'Editeur et ses partenaires ne sauraient garantir l'exactitude, la complétude, l'actualité des informations diffusées sur le Site. L'Editeur et ses partenaires mettent tout en œuvre pour offrir à l'utilisateur des informations et/ou outils disponibles et vérifiés, mais ne sauraient être tenus pour responsables des erreurs ou omissions et/ou d'une absence de disponibilité des informations et des services.<br/>
                            En conséquence, l'utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive, et l'Editeur décline toute responsabilité quant aux possibles imprécisions ou omissions portant sur les informations présentes sur le Site.<br/>

                            <h6>Mise à jour : 22 Juin 2022</h6>
                        </CardContent>
                    </Card>
            </div>

            </div>
        );
    }
}
export default MentionLegales;