import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import './styles/Footer.scss'
import Link from '@mui/material/Link';
import {withTranslation} from "react-i18next";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
class Footer extends Component {

    render() {
        const { t } = this.props;
        return (
            <footer>
                <Grid container justifyContent="center" alignItems="center">
                    <a target="_blank" href="https://www.facebook.com/AppartHomeCity-106195341084745/"><FacebookIcon className="network" /></a>
                    <a target="_blank" href="https://www.instagram.com/ahc_officiel/"><InstagramIcon className="network"/></a>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                        <Link color="inherit" underline="hover" href="/mention-legales">{t('Legal Notice')}</Link>&nbsp;|&nbsp;
                        <Link color="inherit" underline="hover" href="/cgv">{t('Terms of Sales')}</Link>&nbsp;|&nbsp;
                        &copy; 2020 - 2023 AppartHomeCity
                    </Grid>
                </Grid>
            </footer>
        );
    }
}
export default withTranslation()(Footer);