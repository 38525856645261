import React from "react";
import './styles/App.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "../pages/home/Home";
import Header from "./Header";
import Properties from "../pages/properties/Properties";
import Contact from "../pages/contact/Contact";
import Cgv from "../pages/cgv/Cgv";
import MentionLegales from "../pages/mentionLegales/MentionLegales";
import Property from "../pages/property/Property";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/fr';
export default function App() {
    const { t } = useTranslation();
  return (
      <Router>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="App">
                <Header/>

                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/properties" element={<Properties/>}/>
                    <Route path="/property/:id/:checkin/:checkout" element={<Property/>}/>
                    <Route path="/property/:id" element={<Property/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/cgv" element={<Cgv/>}/>
                    <Route path="/mention-legales" element={<MentionLegales/>}/>
                </Routes>
                <ToastContainer autoClose={4000}/>
                <CookieConsent
                    location="bottom"
                    disableButtonStyles={true}

                    buttonText={t("Accept")}
                    declineButtonText={t("Decline")}

                    cookieName="accept-cookie"
                    style={{ background: "#2B373B" }}

                    buttonClasses="accept-cookie"
                    expires={150}
                    onAccept={({ acceptedByScrolling }) => {

                    }}
                >
                    {t("This website uses cookies to enhance the user experience.")}
                </CookieConsent>
                <Footer/>
            </div>
        </LocalizationProvider>
      </Router>
  );
}
