import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import './Contact.scss'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
class ContactInfo extends Component {

    constructor(props) {
        super(props);

        this.nom = props.nom;
        this.tel = props.tel;
        this.mail = props.mail;
        this.adress = props.adress;
    }

    render() {
        return (
            <div  className="cardContact">
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item className="name ta-center">{this.nom}</Grid>
                </Grid>
                <br/>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item className="ta-center">
                        <PhoneOutlinedIcon className="icon-color-app"/> <br/> {this.tel}
                    </Grid>
                </Grid>
                <br/>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item className="ta-center">
                        <MailOutlineOutlinedIcon className="icon-color-app"/> <br/> {this.mail}
                    </Grid>
                </Grid>
                <br/>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item className="ta-center">
                        <HomeOutlinedIcon className="icon-color-app"/> <br/>
                        <div dangerouslySetInnerHTML={{ __html: this.adress }} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default ContactInfo;