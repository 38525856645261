import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe'
import { useTranslation } from 'react-i18next';
import './Property.scss';
import { Helmet } from "react-helmet";
import PropertyService from "../../services/PropertyService";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";

export default function Property() {
    const { id, checkin, checkout } = useParams();
    const [property, setProperty] = useState(null);
    const [propertyId, setPropertyId] = useState(id);
    const [paramUrl, setParamUrl] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (checkout !== '' && checkin !== '') {
            setParamUrl(`&checkin_hide=${checkin}&checkout_hide=${checkout}`)
        }

        PropertyService.getProperty(propertyId).then(property => {
            setProperty(property)
        })
    }, [propertyId, checkin, checkout]);

    if (!property) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                <CircularProgress color="inherit" className="circular-app" />
            </Grid>
        )
    }

    return (
        <div className="property">
            <div className="top"></div>
            <div className="container-fluid">
                <h1>{property.name}</h1>
                <Helmet>
                    <title>{property.name}</title>
                </Helmet>
                <Iframe url={`https://beds24.com/booking2.php?propid=${property.propId}&layout=1&lang=${t('lang')}${paramUrl}`}
                        width="100%"
                        height="1800px"
                        className="iframe"
                        display="initial"
                        loading="Loading"
                        frameBorder="0"
                        position="relative"
                        scrolling="yes"
                />
            </div>
        </div>
    );
}
