import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {I18nextProvider} from 'react-i18next';
import i18 from './i18n';
import App from "./layout/App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18}>
          <App />
      </I18nextProvider>
  </React.StrictMode>
);