import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './Cgv.scss'


export default function Cgv() {
    const [name, setName] = useState("APPARTHOMECITY.FR")

    return (
        <div className="cgv">
            <div className="top"></div>
            <div className="container">
                <Helmet>
                    <title>CGV</title>
                </Helmet>
                <Card>
                    <CardContent>
                        <h1>CONDITIONS GENERALES DE VENTE</h1>

                        <p>
                            Entre les sociétés :<br/><br/>
                            EI LE HENAFF ARNAUD<br/>
                            111, rue de Lessard - 76100 ROUEN<br/>
                            sous le numéro SIREN 821 486 206<br/>
                            représentée par M. LE HENAFF Arnaud en qualité de directeur, dûment habilité aux fins des présentes.<br/><br/>
                            SAS LHB<br/>
                            6, rue du terrain - 76100 ROUEN<br/>
                            sous le numéro SIREN 894 656 453<br/>
                            représentée par M. LE HENAFF Arnaud en qualité de président, dûment habilité aux fins des présentes.<br/><br/>
                            EI LE HENAFF DIDIER<br/>
                            2, allée Marcel Dupré - 76100 ROUEN<br/>
                            sous le numéro SIREN 819 267 998<br/><br/>
                            Les sociétés peuvent être jointes par email en utilisant l'email inscrite sur la page de contact.<br/>
                            Ci-après le « Vendeur » ou la « Société ».<br/>
                            D’une part,<br/>
                            Et la personne physique ou morale procédant à l’achat de produits ou services de la société,<br/>
                            Ci-après, « l’Acheteur », ou « le Client »<br/>
                            D’autre part,<br/>
                            Il a été exposé et convenu ce qui suit :

                        </p>
                        <h2>Préambule</h2>
                        <p>
                            Le Vendeur est éditeur de Produits et Services de nuitées d’appartements meublés de courte durée à destination de consommateurs, commercialisés par l’intermédiaire de son site internet (https://www.apparthomecity.fr). Appart'HomeCity est désignée comme la marque détenue par l'entreprise individuelle LE HENAFF ARNAUD sous le numéro SIREN : 821 486 206 dont le siège est au 111, rue de Lessard - 76100 ROUEN.<br/>
                            La liste et le descriptif des biens et services proposés par la Société peuvent être consultés sur le site susmentionné.
                        </p>
                        <h2>Article 1 : Objet </h2>
                        <p>Les présentes Conditions Générales de Vente déterminent les droits et obligations des parties dans le cadre de la vente en ligne de Produits ou Services proposés par le Vendeur. </p>

                        <h2>Article 2 : Dispositions générales </h2>
                        <p>Les présentes Conditions Générales de Vente (CGV) régissent les ventes de Produits ou de Services, effectuées au travers des sites Internet de la Société, et sont partie intégrante du Contrat entre l’Acheteur et le Vendeur. Elles sont pleinement opposables à l'Acheteur qui les a acceptés avant de passer commande.
                            Le Vendeur se réserve la possibilité de modifier les présentes, à tout moment par la publication d’une nouvelle version sur son site Internet. Les CGV applicables alors sont celles étant en vigueur à la date du paiement (ou du premier paiement en cas de paiements multiples) de la commande. Ces CGV sont consultables sur le site internet de la Société à l'adresse suivante : https://www.apparthomecity.fr/cgv.
                            La Société s’assure également que leur acceptation soit claire et sans réserve en mettant en place une case à cocher et un clic de validation. Le Client déclare avoir pris connaissance de l’ensemble des présentes Conditions Générales de Vente, et le cas échéant des Conditions Particulières de Vente liées à un produit ou à un service, et les accepter sans restriction ni réserve.
                            Le Client reconnaît qu’il a bénéficié des conseils et informations nécessaires afin de s’assurer de l’adéquation de l’offre à ses besoins.
                            Le Client déclare être en mesure de contracter légalement en vertu des lois françaises ou valablement représenter la personne physique ou morale pour laquelle il s’engage.
                            Sauf preuve contraire les informations enregistrées par la Société constituent la preuve de l’ensemble des transactions.
                        </p>

                        <h2>Article 3 : Prix </h2>
                        <p>Les prix de nos produits sont indiqués en euros toutes taxes comprises (TVA et autres taxes applicables au jour de la commande), sauf indication contraire et hors frais de traitement et d’expédition.
                            En cas de commande vers un pays autre que la France métropolitaine vous êtes l’importateur du ou des produits concernés. Des droits/frais de changes ou autres taxes locales ou taxes d’état sont susceptibles d’être exigibles.
                            Ces droits et sommes ne relèvent pas du ressort de { name }. Ils seront à votre charge et relèvent de votre entière responsabilité, tant en termes de déclarations que de paiements aux autorités et organismes compétents de votre pays. Nous vous conseillons de vous renseigner sur ces aspects auprès de vos autorités locales.
                            Toutes les commandes quelle que soit leur origine sont payables en euros. { name } se réserve le droit de modifier ses prix à tout moment, mais le produit sera facturé sur la base du tarif en vigueur au moment de la validation de la commande et sous réserve de disponibilité.
                            Les produits demeurent la propriété de la société { name } y compris lors du séjour des locataires.
                            Attention : dès que le locataire prend possession des lieux (entrée dans l’appartement), la responsabilité des éventuels dommages causés dans l’appartement lui est transférée.
                        </p>

                        <h2>Article 4 : Conclusion du contrat en ligne </h2>
                        <p>Conformément aux dispositions de l'article 1127-1 du Code civil, le Client doit suivre une série d’étapes pour conclure le contrat par voie électronique pour pouvoir réaliser sa commande : <br/>
                            - Information sur les caractéristiques essentielles du Produit ; <br/>
                            - Choix du Produit, le cas échéant, de ses options supplémentaires ; <br/>
                            - Indication des coordonnées essentielles du Client (identification, email, adresse...) ; <br/>
                            - Acceptation des présentes Conditions Générales de Vente ; <br/>
                            - Vérification des éléments de la commande (formalité du double clic) et, le cas échéant, correction des erreurs. Avant de procéder à sa confirmation, l'Acheteur a la possibilité de vérifier le détail de sa commande, son prix, et de corriger ses éventuelles erreurs, ou annuler sa commande. La confirmation de la commande emportera formation du présent contrat ; <br/>
                            - Ensuite, suivi des instructions pour le paiement, paiement des produits et services. <br/>
                            Le Client recevra confirmation par courrier électronique du paiement et réception de la commande. Il recevra un exemplaire au format .pdf des présentes conditions générales de vente ainsi qu'un lien internet pour signer un contrat de location saisonnière. Ce contrat de location reprendra toutes les informations transmises par le Client (identité, adresse, etc...). La signature du contrat de location saisonnière par le Client est obligatoire pour finaliser la commande.<br/>
                            Le Client disposera pendant son processus de commande de la possibilité d'identifier d'éventuelles erreurs commises dans la saisie des données et de les corriger. La langue proposée pour la conclusion du contrat est la langue française.
                            Les modalités de l'offre et des conditions générales de vente sont renvoyées par email à l'acheteur lors de la commande et archivées sur le site web du Vendeur. L'archivage des communications, de la commande, des détails de la commande, ainsi que des factures est effectué sur un support fiable et durable de manière constituer une copie fidèle et durable conformément aux dispositions de l'article 1360 du code civil. Ces informations peuvent être produits à titre de preuve du contrat.
                        </p>

                        <h2>Article 5 : Produits et services </h2>
                        <p>Les caractéristiques essentielles des biens, des services et leurs prix respectifs sont mis à disposition de l’acheteur sur les sites Internet de la société, de même, le cas échéant, que le mode d'utilisation du produit. Conformément à l'article L112-1 du Code la consommation, le consommateur est informé, par voie de marquage, d'étiquetage, d'affichage ou par tout autre procédé approprié, des prix et des conditions particulières de la vente et de l'exécution des services avant toute conclusion du contrat de vente. Dans tous les cas, le montant total dû par l'Acheteur est indiqué sur la page de confirmation de la commande. Le prix de vente du produit est celui en vigueur indiqué au jour de la commande, celui-ci ne comportant par les frais de ports facturés en supplément. Ces éventuels frais sont indiqués à l'Acheteur lors du process de vente, et en tout état de cause au moment de la confirmation de la commande. Le Vendeur se réserve la possibilité de modifier ses prix à tout moment, tout en garantissant l'application du prix indiqué au moment de la commande.
                            Les informations contractuelles sont présentées en détail et en langue française. Les parties conviennent que les illustrations ou photos des produits offerts à la vente n’ont pas de valeur contractuelle. La durée de validité de l’offre des Produits ainsi que leurs prix est précisée sur les sites Internet de la Société, ainsi que la durée minimale des contrats proposés lorsque ceux-ci portent sur une fourniture continue ou périodique de produits ou services. Sauf conditions particulières, les droits concédés au titre des présentes le sont uniquement à la personne physique signataire de la commande (ou la personne titulaire de l’adresse email communiqué).
                        </p>

                        <h2>Article 6 : Clause de réserve de propriété </h2>
                        <p>Les produits et services demeurent la propriété de la Société jusqu’au complet paiement du prix. </p>

                        <h2>Article 7 : Paiement </h2>
                        <p>Le paiement est exigible immédiatement à la réservation. Le Client peut effectuer le règlement uniquement par carte de paiement. Conformément à la législation européeene (SCA), une authentification à deux facteurs (3D SECURE) sera exigée pour les cartes européennes. Les cartes émises par des banques domiciliées hors de France doivent obligatoirement être des cartes bancaires internationales (Mastercard, Visa ou American Express). Le paiement sécurisé en ligne par carte bancaire est réalisé par notre prestataire de paiement. Les informations transmises sont chiffrées dans les règles de l’art et ne peuvent être lues au cours du transport sur le réseau. Une fois le paiement lancé par le Client, la transaction est immédiatement débitée après vérification des informations. Conformément aux dispositions du Code monétaire et financier, l’engagement de payer donné par carte est irrévocable. En communiquant ses informations bancaires lors de la vente, le Client autorise le Vendeur à débiter sa carte du montant relatif au prix indiqué. Le Client confirme qu’il est bien le titulaire légal de la carte à débiter et qu’il est légalement en droit d’en faire usage. En cas d’erreur, ou d’impossibilité de débiter la carte, la Vente est immédiatement résolue de plein droit et la commande annulée.</p>

                        <h2>Article 8 : Délai de rétractation </h2>
                        <p>Il est convenu qu'en cas d'annulation/désistement : </p>

                        <h3> * Offre Flexible 5j :</h3>

                        <h4> du Locataire :</h4>
                        <p>- à plus de 5 jours avant la prise d'effet du bail, le Locataire sera remboursé des arrhes encaissés (100 % du montant de la réservation)</p>
                        <p>- à moins de 5 jours avant la prise d'effet du bail, le Locataire ne sera pas remboursé des arrhes encaissés à la réservation, représentant la totalité du séjour, déduit des frais de ménage et taxe de séjour</p>

                        <h4> du Bailleur :</h4>
                        <p>- dans les 7 jours suivant le désistement, le Bailleur est tenu de verser le double de la totalité du séjour</p>

                        <h3> * Offre Non Remboursable :</h3>

                        <h4> du Locataire :</h4>
                        <p>- le Locataire ne sera pas remboursé des arrhes encaissés à la réservation, représentant la totalité du séjour, déduit des frais de ménage et taxe de séjour</p>

                        <h4> du Bailleur :</h4>
                        <p>- dans les 7 jours suivant le désistement, le Bailleur est tenu de verser le double de la totalité du séjour</p>

                        <h2>Article 9 : Disponibilités</h2>
                        <p>Nos produits sont proposés tant qu’ils sont visibles sur le site { name }.
                            En cas d’indisponibilité de produits après passation de votre commande, nous vous en informerons par mail. Votre commande sera soit automatiquement annulée et aucun débit bancaire ne sera effectué soit nous vous proposerons de vous trouver un autre logement pendant la durée de votre séjour.
                        </p>

                        <h2>Article 10 : Responsabilités</h2>
                        <p>Les produits proposés sont conformes à la législation française en vigueur.
                            { name } ne saurait être tenue pour responsable des dommages résultant d’une mauvaise utilisation du produit acheté.
                            Enfin la responsabilité de { name } ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l’utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la présence de virus informatiques.

                        </p>

                        <h2>Article 11 : Réclamations et médiation </h2>
                        <p>Le cas échéant, l’Acheteur peut présenter toute réclamation en contactant la société au moyen des coordonnées suivantes : EI LE HENAFF ARNAUD - 111, rue de Lessard - 76100 ROUEN ou contact@apparthomecity.fr
                            Conformément aux dispositions des art. L. 611-1 à L. 616-3 du Code de la consommation, le consommateur est informé qu'il peut recourir à un médiateur de la consommation dans les conditions prévues par le titre Ier du livre VI du code de la consommation.
                            En cas d'échec de la demande de réclamation auprès du service client du Vendeur, ou en l'absence de réponse dans un délai de deux mois, le consommateur peut soumettre le différent à un médiateur qui tentera en toute indépendance de rapprocher les parties en vue d'obtenir une solution amiable.
                        </p>

                        <h2>Article 12 : Droits de propriété intellectuelle </h2>
                        <p>Les marques, noms de domaines, produits, logiciels, images, vidéos, textes ou plus généralement toute information objet de droits de propriété intellectuelle sont et restent la propriété exclusive du vendeur. Aucune cession de droits de propriété intellectuelle n’est réalisée au travers des présentes CGV. Toute reproduction totale ou partielle, modification ou utilisation de ces biens pour quelque motif que ce soit est strictement interdite. </p>

                        <h2>Article 13 : Force majeure </h2>
                        <p>L’exécution des obligations du vendeur au terme des présentes est suspendue en cas de survenance d’un cas fortuit ou de force majeure qui en empêcherait l’exécution. Le vendeur avisera le client de la survenance d’un tel évènement dès que possible. </p>

                        <h2>Article 14 : Nullité et modification du contrat </h2>
                        <p>Si l’une des stipulations du présent contrat était annulée, cette nullité n’entraînerait pas la nullité des autres stipulations qui demeureront en vigueur entre les parties. Toute modification contractuelle n’est valable qu’après un accord écrit et signé des parties. </p>

                        <h2>Article 15 : Protection des données personnelles </h2>
                        <p>Conformément au Règlement 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, le Vendeur met en place un traitement de données personnelles qui a pour finalité la vente et la livraison de produits et services définis au présent contrat. L'Acheteur est informé des éléments suivants : - l'identité et les coordonnées du responsable du traitement et, le cas échéant, du représentant du responsable du traitement : le Vendeur, tel qu'indiqué en haut des présentes CGV ; les destinataires ou les catégories de destinataires des données à caractère personnel, s'ils existent : le responsable du traitement, ses services en charge du marketing, les services en charge de la sécurité informatique, le service en charge de la vente, de la livraison et de la commande, les sous-traitant intervenants dans les opérations de livraison et de vente ainsi que toute autorité légalement autorisée à accéder aux données personnelles en question - aucun transfert hors UE n'est prévu - la durée de conservation des données : le temps de la prescription commerciale - la personne concernée dispose du droit de demander au responsable du traitement l'accès aux données à caractère personnel, la rectification ou l'effacement de celles-ci, ou une limitation du traitement relatif à la personne concernée, ou du droit de s'opposer au traitement et du droit à la portabilité des données - La personne concernée a le droit d'introduire une réclamation auprès d'une autorité de contrôle - les informations demandées lors de la commande sont nécessaires à l'établissement de la facture (obligation légale) et la livraison des biens commandés, sans quoi la commande ne pourra pas être passée. Aucune décision automatisée ou profilage n'est mis en œuvre au travers du processus de commande. </p>

                        <h2>Article 16 : Archivages – Preuves</h2>
                        <p>{ name } archivera les contrats de location et les factures émises sur un support fiable et durable constituant une copie fidèle conformément aux dispositions de l’article 1348 du Code civil.
                            Les registres informatisés de { name } seront considérés par toutes les parties concernées comme preuve des communications, commandes, paiements et transactions intervenus entre les parties.
                        </p>

                        <h2>Article 17 : Droit applicable et clauses </h2>
                        <p>Toutes les clauses figurant dans les présentes conditions générales de vente, ainsi que toutes les opérations d’achat et de vente qui y sont visées, seront soumises au droit français.
                            La nullité d'une clause contractuelle n'entraîne pas la nullité des présentes conditions générales de vente.
                        </p>

                        <h6>Mise à jour : 22 Juin 2022</h6>

                    </CardContent>
                </Card>
            </div>
        </div>
    );
}