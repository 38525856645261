import axios from './ImroomApiConfig';

class Imroom {

    static call(url, data) {
        return axios.request({
            method: 'POST',
            url: url,
            data: data
        }).then (res => {
            return res;
        }).catch(err => {
            return err.response;
        })
    }
}
export default Imroom;