import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import PropertyService from "../../services/PropertyService";
import './Properties.scss';
import {Helmet} from "react-helmet";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as moment from "moment/moment";
class Properties extends Component {

    format;
    constructor(props) {
        super(props);

        this.format = "YYYY/MM/DD"
        this.state = {
            properties: null,
            originalProperties: null,
            openPickerTo: false,
            dateFrom: moment(),
            queryInProgress: true,
            dateTo: moment().add(1, 'days')
        };
        this.onChangeForm = this.onChangeForm.bind(this);
    }

    componentDidMount() {
        PropertyService.getProperties().then(properties => {
            this.setState({
                properties,
                originalProperties: properties,
                queryInProgress: false
            })
        })
    }

    searchProperties = () => {
        this.setState({properties: null, queryInProgress: true}, () => {
            PropertyService.getPropertiesWithDates(
                this.state.originalProperties,
                this.state.dateFrom.format('YYYYMMDD'),
                this.state.dateTo.format('YYYYMMDD')
            ).then(properties => {
                this.setState({properties, queryInProgress: false})
            })
        })
    }

    redirect(propId) {
        let url = `/property/${propId + "/"+this.state.dateFrom.format('YYYY-MM-DD')+"/"+this.state.dateTo.format('YYYY-MM-DD')}`
        window.location.assign(url)
    }

    onChangeForm(stateObject, event) {
        let dateTo = this.state.dateTo
        let dateFrom = this.state.dateFrom
        if (stateObject === 'dateFrom') {
            dateFrom = event.target.value
            dateTo = event.target.value
        } else {
            dateTo = event.target.value
        }

        this.setState({dateTo, dateFrom})
    }

    render() {
        const { t } = this.props;
        return (
            <div className="properties">
                <Helmet>
                    <title>{t("Our Properties")}</title>
                </Helmet>

                <div className="top"></div>
                <div className="container-fluid">
                    <Grid className="datepicker" container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item>
                            <DatePicker
                                id='dateFrom'
                                name='dateFrom'
                                label={t("Check-in")}
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                disablePast
                                format={this.format}
                                onChange={(e) => this.onChangeForm("dateFrom", {target:{value:e}})}
                                value={this.state.dateFrom}
                            />
                        </Grid>
                        <Grid item>
                            <DatePicker
                                id='dateTo'
                                name='dateTo'
                                label={t("Check-out")}
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                disablePast
                                format={this.format}
                                onChange={(e) => this.onChangeForm("dateTo", {target:{value:e}})}
                                value={this.state.dateTo}
                            />
                        </Grid>
                    </Grid>
                    <Grid className="btn-search" container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={this.searchProperties}
                                disabled={this.state.queryInProgress}
                            >
                                {t("Search")}
                            </Button>
                        </Grid>
                    </Grid>

                    { this.state.properties !== null ? (
                        <Grid container spacing={2} justifyContent="center">
                            { this.state.properties.length === 0 &&
                                <h2>{t("No apartment matches your dates")}</h2>
                            }
                            { this.state.properties.map((property, index) => {
                                return (
                                    <Grid key={index} item>
                                        <Card onClick={() => this.redirect(property.propId) }>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="300"
                                                    image={property.image}
                                                />
                                                <CardContent>
                                                    <Typography variant="h6" component="h2" align="center" style={{fontSize:'1.15rem'}}>
                                                        {property.name}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Link
                                                            href={"/property/" + property.propId + "/"+this.state.dateFrom.format('YYYY-MM-DD')+"/"+this.state.dateTo.format('YYYY-MM-DD')}
                                                        >
                                                            {t("Learn more")}
                                                        </Link>
                                                    </Grid>
                                                    <Grid item xs={6} className="ta-right">
                                                        {t("From")} {property.roomTypes[0].minPrice} €{t("/night*")}
                                                    </Grid>
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            })}
                            <Grid container spacing={2} justifyContent="center">
                                <br/>
                                {t("* Without cleaning fee and tourist tax")}
                            </Grid>
                        </Grid>

                    ):(
                        <Grid container justifyContent="center">
                            <CircularProgress color="inherit" className="circular-app"/>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}
export default withTranslation()(Properties);